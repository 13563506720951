import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VFooter,{attrs:{"app":""}},[_c(VRow,{attrs:{"justify":"start","no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('div',{attrs:{"align":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'left'}},[_c('small',[_vm._v("Copyright © "+_vm._s(new Date().getFullYear())+", Wg Gen Web. ")]),_c('small',[_vm._v("This work is licensed under "),_c('a',{staticClass:"pr-1 pl-1",attrs:{"href":"http://www.wtfpl.net/","target":"_blank"}},[_vm._v("WTFPL License.")])])])])],1),_c(VRow,{attrs:{"justify":"end","no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","lg":"6","md":"12","sm":"12"}},[_c('div',{attrs:{"align":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'right'}},[_c('small',[_vm._v("Created with")]),_c(VIcon,{staticClass:"pr-1 pl-1"},[_vm._v("mdi-heart")]),_c('span',[_vm._v("by")]),_c('a',{staticClass:"pr-2 pl-1",attrs:{"href":"mailto:vx3r@127-0-0-1.fr"}},[_vm._v("vx3r")]),_c('a',{attrs:{"href":'https://github.com/vx3r/wg-gen-web/commit/' + _vm.version}},[_c('kbd',[_vm._v("Version: "+_vm._s(_vm.version.substring(0,7)))])])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }